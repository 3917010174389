export default {
  "title": "Bienvenido a React usando: react-i18next",
  "description": {
    "part1": "To get started, edit <1>src/App.js</1> and save to reload.",
    "part2": "Switch language between english and german using buttons above."
  },
  "login": {
    "emailEmpty": "Debe ingresar un email",
    "passwordEmpty": "Debe ingresar una contraseña",
    "emailPlaceholder": "example@mail.com",
    "recoverPassword": "¿Olvidaste la contreseña?",
    "enter": "Iniciar sesión",
    "wrongEmail": "La dirección de email ingresada es incorrecta.",
    "send": "Enviar",
    "passwordsDoesntMatch": "Las contraseñas deben coincidir",
    "passwordsMinLength": "La contraseña debe poseer al menos 5 caracteres",
    "passwordNumber": "La contraseña debe poseer al menos un número",
    "passwordPlaceholder": "Contraseña",
    "passwordChanged": "La contraseña ha sido cambiada con éxito",
    "confirmPasswordPlaceholder": "Confirmar contraseña",
    "emailSent": "Email enviado con éxito.",
    "changePassword": "Genera tu nueva contraseña",
    "recoverPasswordTitle": "Solicitar cambio de contraseña",
    "tipPassword": "Tu contraseña debe tener un mínimo de 5 carácteres."
  },
  "yes": "Sí",
  "no": "No",
  "documents": {
    "modalTitle": "Videos y Documentos",
    "videoTitle": "Videos Tutoriales",
    "textTitle": "Documentos",
    "textTitleUrl": "Direcciones URL",
    "add": "Agregar",
    "edit": "Editar",
    "save": "Guardar",
    "pin": "Destacar",
    "unpin": "No destacar",
    "remove": "Eliminar",
    "title": "Título",
    "link": "Link",
    "emptyFields": "Debe completar todos los campos.",
    "titleLengthError": "El título debe tener al menos 5 caracteres.",
    "linkFormatError": "El link ingresado debe ser válido.",
    "savedSuccessfully": "Guardado correctamente.",
    "removedSuccessfully": "Eliminado correctamente.",
    "pinnedSuccessfully": "Destacado correctamente.",
    "unpinnedSuccessfully": "Destacado eliminado correctamente.",
    "confirmDelete": "Está seguro que desea eliminar este documento?"
  },
  "support": {
    "title": "Soporte",
    "description": "Si tienes dudas, ¡envíanos un mensaje!",
    "submited": "Soporte cargado correctamente",
    "allFields": "Debe completar área y mensaje.",
    "message": "Mensaje",
    "area": "Area",
    "emptyMessage": "Por favor ingrese el mensaje",
    "messagePlaceholder": "Ingrese aquí el cuerpo del mensaje",
    "messageButtonTooltip": "Enviar mensaje",
    "messageButton": "Enviar"
  },
  "menu": {
    "dashboard": "Dashboard",
    "compras": "Compras",
    "listing": "Listing",
    "logistic": "Logística",
    "myAccount": "Mi Cuenta",
    "onBoarding": "Onboarding",
    "analytics": "Analytics",
    "soporte": "Soporte",
    "estado": "Estado",
    "tus_productos": "Tus productos",
    "tus_publicaciones": "Tus publicaciones"
  },
  "home": {
    "assistance": "Resources",
    "welcome": "Bienvenido",
    "viewStock": "Ver stock",
    "newListing": "Nuevo listing",
    "fullProfile": "Perfil completo",
    "incompleteProfile": "Perfil incompleto",
    "listing": {
      "title": "LISTADO POR GRUPO",
      "subTitle": "Tus publicaciones activas en marketplaces",
      "salesChannels": "CANALES DE VENTA",
      "image": "IMAGEN",
      "name": "NOMBRE",
      "sku": "SKU",
      "category": "CATEGORIA",
      "onboarding": "ONBOARDING",
      "price": "PRECIO",
      "state": "ESTADO",
      "stock": "STOCK",
      "orders": "ORDENES",
      "actions": "ACCIONES",
      "edit": "Editar",
      "generateOrder": "Generar orden",
      "tooltipSyncListings": "Obtiene listings desde los marketplaces",
      "tooltipSyncStockFBA": "Sincroniza con el inventario de FBA",
      "tooltipUnlinkMatchesFBA": "Desvincular matcheos para FBA",
      "syncListings": "Sync Listings",
      "syncStockFBA": "Sync Stock",
      "unlinkMatchesFBA": "Unlink FBA",
      "export": "Exportar",
      "add": 'Nuevo listing',
      "error": "Error al obtener los listings desde los marketplaces",
      "confirmUpdateCanPublish": "Está seguro que desea cambiar dicho permiso?",
      "tooltipCanPublish": "El cliente puede sincronizar su seller y publicar?",
      "tooltipCanAutofulfillmentFBA": "Gestionar automáticamente el fulfillment outbound con FBA?",
      "successfulPermissions": "Permiso actualizados",
      "unsuccessfulPermissions": "Error al actualizar los permisos del partner",
      "modalFBM": {
        "marketplaces": "MARKETPLACES",
        "marketplacesDescription": "El sku actual tiene presencia en los siguientes marketplaces. Seleccione en cuál/les desea aplicar la actualización",

      }
    },
    "sale-order": {
      "title": "Pedidos",
      "subTitle": "Estado de tus Pedidos en todos los Marketplaces",
      "confirmCancel": "Está seguro que desea cancelar?"
    }
  },
  "option": {
    'personalComplete': 'Datos generales completados',
    'mustAddSeller': 'Debes sincronizar un seller',
    'sellerSync': 'Seller sincronizado',
    'personalIncomplete': 'Completa la sección Mi Cuenta para continuar',
    'needSyncPermission': 'Ya estás habilitado para comenzar con la carga de Listings',
    'listingsCargados': 'Ya has iniciado la carga de tus Listings!'
  },
  "myAccount": {
    "area": "Industria",
    "subPrincipalTitle": "Información general de tu empresa",
    "bankSubtitle": "Completa los siguientes campos requeridos para configurar tu perfil de empresa.",
    "return": "Volver",
    "save": "Guardar",
    "country": "País",
    "successfulMessage1": "Cuenta verificada con éxito.",
    "successfulMessage2": "Credenciales guardadas con éxito.",
    "failedMessage": "Revise las credenciales.",
    "next": "Continuar",
    "tooltip1": "Primero debe guardar los datos de su cuenta bancaria.",
    "tooltip2": "Primero debe verificar sus credenciales.",
    "phone": "Teléfono",
    "cancel": "Cancelar",
    "finish": "Finalizar",
    "save": "Guardar",
    "update": "Actualizar",
    "address": "Dirección",
    "zip": "Código postal",
    "section": "Mi Cuenta",
    "passport": "RUT contacto - Pasaporte",
    "sideBarItem1": "Mis datos",
    "nif": "Número de registro-NIF-Rut empresa",
    "fullName": "Nombre y Apellido",
    "divider1": "Datos de la Empresa",
    "divider2": "Persona de contacto",
    "fantasyName": "Razón social",
    "selectDate": "Elige un día y una hora",
    "employeesNumber": {
      "name": "Número de empleados",
      "to1": "1",
      "to5": "Entre 2 y 5",
      "to10": "Entre 6 y 10",
      "to25": "Entre 11 y 25",
      "to50": "Entre 26 y 50",
      "to200": "Entre 51 y 200",
      "to1000": "201 o más",
      "to10000": "Entre 1.001 y 10.000",
      "10000plus": "Más de 10.001",
    },
    "salesRange": {
      "name": "Rango de ventas anuales",
      "10to100": "Entre $0 - $100.000 USD",
      "101to200": "Entre $100.001 - $1.000.000 USD",
      "201to500": "Entre $1.000.000 - $4.000.000 USD",
      "501to1000": "Sobre $4.000.000 USD",
    },
    "projectName": "Tipo de Cliente",
    "birthday": "Fecha de nacimiento",
    "sideBarItem2": "Cuenta bancaria",
    "sideBarItem3": "Seller account",
    "sideBarItem4": "Agendar reunión",
    "checkSP": "Verificar Credenciales",
    "companyName": "Nombre de la marca",
    "title1": "Información general de la empresa",
    "title2": "Datos de Cuenta Bancaria",
    "title3": "Configuración de Cuentas para Vendedores",
    "subtitle3": "Conecta tu cuentas ya creadas en los marketplaces (o si no las tienes, créalas con la ayuda de nuestro manual paso a paso) y luego sincronízalas con nuestra plataforma.",
    "title4": "Reunión de Bienvenida",
    "subtitle4": "Elige un día y hora para agendar una reunión inicial junto a tu Onboarding Specialist.",
    "subtitle": "Completa los siguientes campos requeridos para configurar tu perfil de empresa.",
    "banks": {
      "bank": "Banco",
      "accountNumber": "Número de la cuenta",
      "alert": "Debes asignar una cuenta bancaria en el país de destino para recibir tus ventas! En caso de no contar con cuenta bancaria, puede reemplazarse por una pasarela de pago que ofrecen empresas. En LAP tenemos una alianza con Payoneer (AGREGAR HIPERVINCULO), empresa lider en simular cuentas bancarias en múltiples países y transferir depósitos a cuentas bancarias en tu país de origen. Si quieres crear una cuenta en Payoneer con los beneficios de su alianza con LAP, asi click en el siguiente link: Activar “Payoneer & LAP”"
    },
    "sellerAccount": {
      "card1": "Documentos",
      "card2": "Agendar reunión",
      "card3": "Agendar reunión",
      "card1Description": "Descarga nuestro manual con el paso a paso para crear tu cuenta de seller en Amazon",
      "card1Button": "Ver Documento",
      "card2Description": "Seleccionar fecha",
      "card3Description": "Seleccionar fecha",
      "divider1": "Account in Amazon Web Service (AWS)",
      "divider2": "Account in Seller Central (SP)",
      "descriptionCard1": "Debe ingresar al enlace siguiente para visualizar la guía y completar todos los pasos: Registrarse como desarrollador, Crear y configurar políticas y entidades de IAM y Registrando su aplicación. En cada input, puede encontrar una ayuda sobre el símobolo ⓘ .",
      "descriptionCard2": "Lorem Ipsum is simply dummy text of the printing and",
      "input1.1": "Tengo cuenta",
      "input1.2": "Usar cuenta del Partner",
      "input1.3": "Seleccione una cuenta",
      "input1Description": "Sí",
      "input2": "Estado",
      "shop": "Tienda",
      "inputShopDescription": "Favor ingrese ID de su tienda de Shopify",
      "migrateListing": "Migración",
      "migrateListingInfo": "Debe convertir los anuncios de eBay existentes en objetos API para poder realizar la sincronización.",
      "input2Description1.1": "Pendiente",
      "input2Description1.2": "Cuando se valide tu cuenta seller, podrás comenzar a trabajar sobre tus listings.",
      "input2Description2.1": "Cuenta activa",
      "switchInfo": "Atención: Si seleccionas 'No' se desvinculará la cuenta y habrá que asociarla nuevamente",
      "truePolicy": "Política configurada",
      "falsePolicy": "Política no configurada",
      "ebayLinkToPolicies": "Enlace a sus políticas",
      "AmazonInputs": {
        "input3Description": "Es el ID de su cuenta en Amazon Web Service. Punto 7 del paso 2: Cree un usuario de IAM.",
        "input4Description": "Se obtiene al descargar las claves de acceso secreta de AWS, luego de crear un usuario IAM. Punto 8 del paso 2: Cree un usuario de IAM.",
        "input5Description": "Se obtiene al descargar las claves de acceso secreta de AWS, luego de crear un usuario IAM. Punto 8 del paso 2: Cree un usuario de IAM.",
        "input6Description": "Es el nombre que ingresó en el punto 7 del paso 4: Cree una función/rol de IAM.",
        "input7Description": "Se obtiene en el punto 8 del paso 4: Cree una función/rol de IAM.",
        "input8Description": "Inicie sesión en Seller Central y haga clic en Settings > Account Information > Your seller Profile.",
        "input9Description": "Inicie sesión en Seller Central y haga clic en Apps & Services > Develop Apps > View debajo de las credenciales de LWA para la aplicación que desee."
      },
      "EbayInputs": {
        "input3Description": "Es el ID de su cuenta en Ebay.",
        "input4Description": "Se obtiene en ..........",
        "input5Description": "Se obtiene en ..........",
        "input6Description": "Se obtiene en ..........",
        "input7Description": "Se obtiene en ..........",
        "input8Description": "Se obtiene en ..........",
        "input9Description": "Se obtiene en ..........",
        "fulfillmentPolicy": "Política de envío",
        "paymentPolicy": "Política de pago",
        "returnPolicy": "Política de devoluciones",
        "inventoryLocationPolicy": "Ubicación de inventario",
        "loadingPolicies": "Políticas de la cuenta",
      },
      "WalmartInputs": {
        "input3Description": "Es el ID de su cuenta en Walmart.",
        "input4Description": "Se obtiene en ..........",
        "input5Description": "Se obtiene en ..........",
        "input6Description": "Se obtiene en ..........",
        "input7Description": "Se obtiene en ..........",
        "input8Description": "Se obtiene en ..........",
        "input9Description": "Se obtiene en .........."
      },
      "credentials": {
        "description1": "Abra la guía completa y comience por el siguiente paso, dentro de Seller Central.",
        "description2": "Continúe con ",
        "description3": "Para completar los inputs de abajo, complete el siguiente paso.",
        "description4": "Para completar los inputs de abajo, complete el siguiente paso.",
        "description5": "Luego complete el paso",
        "description6": "Finalice ésta sección con el paso.",
        "description7": "Para completar los inputs de abajo, comience por el siguiente enlace (dentro de su Seller Central, debe llenar un formulario).",
        "description8": "Luego obtenga sus credenciales en ",
        "step1": "REGISTERING AS A DEVELOPER",
        "step2": "STEP 1. CREATE AN AWS ACCOUNT",
        "step3": "STEP 2. CREATE AN IAM USER",
        "step4": "STEP 3. CREATE AN IAM POLICY.",
        "step5": "STEP 4. CREATE AN IAM ROLE",
        "step6": "STEP 5. ADD AN SECURITY TOKEN SERVICE TO IAM USER.",
        "step7": "REGISTERING YOUR APPLICATION.",
        "step8": "SP CREDENTIALS."
      }
    }
  },
  "onboarding": {
    "section": "Onboarding",
    "subtitle": "Lorem Ipsum is simply dummy text of the printing and",
    "sideBarItem1": "Definición de Productos",
    "sideBarItem2": "Listing",
    "sideBarItem3": "Pricing",
    "sideBarItem4": "Imágenes",
    "sideBarItem5": "Crear borrador",
    "sideBarItem6": "Fulfillment",
    "tab1": "Tu propuesta",
    "tab2": "Propuesta LAP",
    "alertMessage": "Tu encargado LAP armó esta propuesta a medida.",
    "alertButtonClose": "Eliminar",
    "alertButtonGoBack": "Volver",
    "search": "Buscar",
    "alertButtonSave": "Guardar borrador",
    "modalVariantsButtonSave": "Guardar",
    "alertButtonSend": "Enviar",
    "title1": "Pedido de listing",
    "subtitle1": "Lorem Ipsum is simply dummy text of the printing and",
    "rejectProposal": "Reformular propuesta",
    "rejectMessage": "Comentario",
    "draftSavedSuccessfully": "Borrador guardado con éxito",
    "sentSuccessfully": "Enviado correctamente",
    "confirmGoBack": "Está seguro que desea retorceder al paso anterior?",
    "confirmDelete": "Está seguro que desea eliminar el listing?",
    "add": "Agregar",
    "order": {
      "title": "Listing",
      "titleDescription": "",
      "step1Input1": "Categoría",
      "step1Input2": "Canal de venta",
      "step1Input3": "Variantes",
      "step1Variant1": "Es un producto único",
      "step1Variant2": "Tiene variantes",
      "step1Input4": "Referencia",
      "step1Input5": "Título",
      "step1Input7": "Marca",
      "step1Input8": "Bullet Point 1",
      "step1Input9": "Bullet Point 2",
      "step1Input10": "Bullet Point 3",
      "step1Input11": "Bullet Point 4",
      "step1Input12": "Bullet Point 5",
      "step1Input13": "Descripción",
      "step1Input14": "Ingredientes o componentes",
      "step1Input15": "Peso",
      "step1Input15-1": "Unidad",
      "step1Input16": "Sabor",
      "step1Input17": "Precio de referencia",
      "step1Input18": "SKU",
      "step1Input19": "Código EAN/UPC",
      "step1Input20": "Pack",
      "step1Input21": "Comentarios LAP",
      "step1Input22": "Variantes",
      "step1Input23": "UPC",
      "step1Input1Description": "Nombre interno del producto simplificado. Por ejemplo: “Botellas- Pack de 2”",
      "step1Input2Description": "Seleccionar los canales a los que se quiere comercializar",
      "step1Input3Description": "Corresponde a sabores, tallas, gramajes, color, etc.",
      "inputCategoryForLAP": "Seleccionar la categoría que identifica a tu producto",
      "btnSearchCategoriesForLAP": "Buscar categorías",
      "inputCategoryForPartner": "Ingrese el nombre de la categoría que identifica a tu producto",
      "step1Input5Description": "Corresponde al título del producto en Amazon",
      "step1Input6Description": "Lorem Ipsum is simply",
      "step1Input7Description": "Atributo diferenciador del producto. Ejemplo: Características, origen, beneficio, certificaciones, forma de uso.",
      "step1Input8Description": "Atributo diferenciador del producto. Ejemplo: Características, origen, beneficio, certificaciones, forma de uso.",
      "step1Input9Description": "Atributo diferenciador del producto. Ejemplo: Características, origen, beneficio, certificaciones, forma de uso.",
      "step1Input10Description": "Atributo diferenciador del producto. Ejemplo: Características, origen, beneficio, certificaciones, forma de uso.",
      "step1Input11Description": "Atributo diferenciador del producto. Ejemplo: Características, origen, beneficio, certificaciones, forma de uso.",
      "step1Input12Description": "HIstoria de la marca, empresa, producto, elementos diferenciadores",
      "step1Input13Description": "Lorem Ipsum is simply",
      "step1Input14Description": "Lorem Ipsum is simply",
      "step1Input15Description": "Peso bruto de la unidad de venta (producto más packaging)",
      "step1Input15-1Description": "Unidad de medida del peso bruto: Gr, Kilos, Libras, ml, cm, Inches, Oz.",
      "step1Input16Description": "Lorem Ipsum is simply",
      "step1Input17Description": "Precio de comercialización",
      "step1Input18Description": "Interno del cliente en caso de aplicar o creado por LAP si viene sin completar",
      "step1Input19Description": "Código de barras el producto. (Es obligatorio que el código esté verificado por GS1)",
      "step1Input20Description": "Seleccionar sólo si la unidad de venta corresponde a un pack, es decir, la unidad de venta incluye más de un producto. Ejemplo: Pack de 4 unidades de mermeladas.",
      "step1Input21Description": "Lorem Ipsum is simply",
      "step1Input22Description": "Lorem Ipsum is simply",
      "step1Input23Description": "Lorem Ipsum is simply",
      "divider1": "Atributos",
      "formListing": {
        "brand": "P&M Foods",
        "item_name": " Muffin de Legumbres Sabor Chocolate, Mezcla lista para preparar de forma fácil y en minutos. Con apenas un 30% de las calorías de un muffin tradicional",
        "item_type_keyword": "Muffin Mix",
        "product_description": "Mezcla de ingredientes en polvo, para preparar de forma fácil y en minutos muffin de legumbres sabor chocolate, con apenas un 30% de las calorías de un muffin tradicional, ideal para darse un gusto libre de culpas y sin temor a consumir cantidades excesivas de calorías.  Contiene lentejas, garbanzos y arvejas. Sin azúcar añadida, fuente de fibra dietaria y proteinas. Bajo aporte de grasas total y bajo aporte de grasa saturada. Contiene prebióticos para una mejor y salud digestiva, cacao real y sabor natural a chocolate. Libre de gluten, lactosa y huevo, apto para vegetarianos y flexitarianos. Solo se requiere agregar agua y aceite, batir y hornear. También se puede preparar en microondas para lo cual incorpora la mezcla en una taza, revuelve con una cuchara y lleva al microondas a máxima potencia por 1 minuto y 30 segundos. Lleva tu muffin de legumbres sabor chocolate como snack y disfrútalo en cualquier momento. Contiene 2 sobres de 140 gramos cada uno. Duración 12 meses a partir de la fecha de elaboración. Con el muffin de legumbres chocolate de P&M Foods, lo que tanto te gusta comer ahora te hace bien.",
        "bullet_point": "100 calorías por porción de consumo, por lo que contiene un 30% de las calorías de un muffin tradicional. Sin azúcar añadida por lo que es un producto apto para personas con diabetes. 6 gramos de proteínas y 5 gramos de fibra dietaria por porción",
        "bullet_point0": "100 calorías por porción de consumo, por lo que contiene un 30% de las calorías de un muffin tradicional. Sin azúcar añadida por lo que es un producto apto para personas con diabetes. 6 gramos de proteínas y 5 gramos de fibra dietaria por porción",
        "bullet_point1": "Contiene prebióticos para tener una mejor salud digestiva",
        "bullet_point2": "Libre de gluten y lactosa, apto para celiacos e intolerantes a la lactosa",
        "bullet_point3": "Contiene una proteína completa y de buena calidad, ya que contiene una combinación balanceada de legumbres y cereales",
        "bullet_point4": "Combinación balanceada de ingredientes con efecto saciador, es decir, quita el hambre por horas",
        "bullet_point5": " ",
        "externally_assigned_product_identifier": " ",
        "merchant_suggested_asin": " ",
        "item_type_name": " ",
        "model_number": " ",
        "model_name": " ",
        "fulfillment_availability": " ",
        "condition_note": " ",
        "product_tax_code": " ",
        "merchant_release_date": " ",
        "merchant_shipping_group": " ",
        "generic_keyword": " ",
        "special_feature": " ",
        "lifestyle": " ",
        "style": " ",
        "age_range_description": " ",
        "lining_description": " ",
        "pattern_type": " ",
        "subject_character": " ",
        "special_size_type": " ",
        "color": " ",
        "occasion_type": " ",
        "theme": " ",
        "sole_material": " ",
        "toe_style": " ",
        "arch_type": " ",
        "height_map": " ",
        "specific_uses_for_product": " ",
        "sport_type": " ",
        "embellishment_feature": " ",
        "safety_data_sheet_url": " ",
        "import_designation": " ",
        "fur": " ",
        "pesticide_marking": " ",
        "fcc_radio_frequency_emission_compliance": " ",
        "hazmat": " ",
        "child_parent_sku_relationship": " "
      }
    },
    "images": {
      "title": "Imágenes",
      "subtitle": "Fotografías profesionales de tus productos en Amazon",
      "formTitle1": "Imágenes",
      "formTitle2": "Opcional",
      "formTitle3": "Variaciones",
      "formSubtitle": "Compartenos el link de Youtube de un video complementario para publicar tu listing. Es requisito que el seller tenga registro de marca (Trademark) configurado previamente",
      "ebayFormSubtitle": "Lorem Ipsum is simply dummy text of the printing",
      "amazonFormSubtitle": "",
      "walmartFormSubtitle": "Lorem Ipsum is simply dummy text of the printing",
      "headerPanelCollapse": "Estándares de todas las imágenes del producto, para el mercado seleccionado.",
      "descriptionPanelCollapse": "",
      "alertMessage": "Carga tus fotos según los lineamientos.",
      "input1": "Imágenes para Aprobación de Categoría",
      "input2": "Imagen Principal de Listing",
      "input3": "Foto complementaria del producto:",
      "input4": "Foto complementaria del producto 2:",
      "input5": "Foto complementaria del producto 3:",
      "input6": "Video",
      "input1Description": "Aprobación de Categoría - hasta 10 imágenes y 2 obligatorias",
      "input2Description": "Agrega la foto principal del producto con fondo blanco.",
      "input3Description": "Agregar fotos profesionales explicativas del producto, mostrando todas sus caras, características diferenciadoras, modo de uso, etc.",
      "input4Description": "Agregar fotos profesionales explicativas del producto, mostrando todas sus caras, características diferenciadoras, modo de uso, etc.",
      "input5Description": "Agregar fotos profesionales explicativas del producto, mostrando todas sus caras, características diferenciadoras, modo de uso, etc.",
      "input6Description": "Video opcional cápsula de la  marca y/o producto.",
      "draggerMessage": "Haga clic o arrastre el archivo aquí.",
      "inputTextArea": "Comentarios LAP",
      "inputTextAreaDescription": "Lorem Ipsum is simply dummy text of the printing and"
    },
    "measuresAndPrices": {
      "title": "Pricing",
      "titleDescription": "Completar la siguiente tabla con las medidas y peso de la unidad de venta seleccionada (producto unitario o pack según corresponda)F",
      "subtitle": "Dimensiones y Peso de la Unidad de Venta",
      "subtitleDescription": " ",
      "width": "Ancho",
      "widthDescription": "Ancho",
      "height": "Alto",
      "heightDescription": "Alto",
      "length": "Largo",
      "lengthDescription": "Largo",
      "weight": "Peso Bruto",
      "netWeight": "Peso Neto",
      "weightUnity": "Unidades",
      "netWeightUnity": "Unidades",
      "measureUnity": "Unidades",
      "weightDescription": "Peso",
      "volume": "Volumen",
      "volumeDescription": "Volumen",
      "LAPComment": "Comentario LAP",
      "LAPCommentDescription": "Comentario LAP",
      "costsTitle": "Costos del producto",
      "costsDescription": "Costos del producto",
      "help": "Ayuda",
      "referral": "REFERRAL FEE",
      "referralItem1": "Costo de Amazon por estar publicado",
      "referralItem2": "Depende de la categoría del producto",
      "referralItem3": "% aplica sobre el precio de venta",
      "fulfillment": "FULFILLMENT (FBA)",
      "fulfillmentItem1": "Costo de Amazon por despacho desde sus bodegas al cliente final",
      "fulfillmentItem2": "Depende de las dimensiones y peso de la unidad de venta",
      "fulfillmentItem3": "Monto fijo por unidad de venta",
      "storage": "STORAGE",
      "storageItem1": "Costo de Amazon por almacenar los productos en sus bodegas",
      "storageItem2": "Depende de las dimensiones de la unidad de venta",
      "storageItem3": "Monto fijo mensual por unidad de venta",
      "costoUnitari": "COSTO DE ENVÍO UNITARIO",
      "costoUnitariItem1": "Costo de envío en avión o barco según la decisión que se haya tomado con el equipo",
      "costoUnitariItem2": "Se respalda de una cotización de envío",
      "freeLap": "FEE LAP",
      "freeLapItem1": "Comisión por venta de LAP",
      "freeLapItem2": "Acordado y respaldado en el Términos y Condiciones firmado",
      "freeLapItem3": "% aplica sobre el precio de venta",

      "infoItem": "Esta cadena de precios no incluye el costo de envío asociado a la exportación de sus productos. Para mayor información puedes contactar a tu encargado de Onboarding."
    },
    "fulfillment": {
      "title": "Fulfillment:",
      "titleDescription": "Proceso de recepción, empaquetado y envío de mercancía",
      "type": "Tipo de Fulfillment",
      "typeDescription": "Selecciona el tipo de fulfillment que se utilizará para el envío de tus productos.",
      "FBA": "FBA - Fulfillment by Amazon",
      "FBADescription": "Los vendedores o sellers envían sus productos a un centro de almacenamiento y distribución de Amazon en el país de destino. Allí se almacenan hasta que se genera una orden de compra y luego se envía al cliente final.",
      "FBL": "FBL - Fulfillment by LAP",
      "FBLDescription": "Los vendedores o sellers toman la responsabilidad de almacenamiento (bodega en país de destino o de origen), junto con la gestión de LAP para la distribución de los productos al cliente final.",
      "FBM": "FBM - Fulfillment by Merchant/Seller",
      "FBMDescription": "Los vendedores o sellers toman la responsabilidad completa de logística, almacenamiento y distribución al cliente final (no se utilizan las bodegas de Amazon), ya sea utilizando bodegas en país de destino o en origen.",
      "LAPComment": "Comentario LAP",
      "LAPCommentDescription": "Comentario LAP",
    },
    "price": {
      "title": "Definición de Productos",
      "titleDescription": "Selecciona el marketplace al que quieres subir un nuevo producto y la categoría a la que pertenece.",
      "competitors": "Estudio de competidores",
      "competitorsDescription": "Competitors description",
      "clientFile": "Archivo para el cliente",
      "clientFileDescription": "Client file description",
      "clientFilePlaceholder": "URL del archivo",
      "uploadClientFile": "Subir archivo",
      "suggestedPrice": "Precio sugerido",
      "suggestedPriceDescription": "Description",
      "product": "SKU",
      "name": "TITULO",
      "price": "PRECIO DE VENTA",
      "actions": "ACCIONES",
      "delete": "Eliminar",
      "add": "Agregar",
      "groupTitle": "Nombre de Referencia del Producto",
      "groupTitleDescription": "Escriba el nombre general de la familia de productos. No considerar características específicas de variaciones. Por ejemplo, si son barritas de cereal de sabores, escribir sólo “barritas de cereal”",
      "productTypeWarningMessage": "La categoría seleccionada está asociada a un Product Type no soportado, temporalmente, por la API de Amazon. No podrá crear el producto.",
      "productTypeSuccessMessage": "La categoría seleccionada está asociada a un Product Type soportado por la API de Amazon. Podrá crear el producto.",
      "loadingProductType": "Verificando el Product Type",
      "inputCategoryForLAP": "Seleccionar la categoría que identifica a tu producto",
      "btnSearchCategoriesForLAP": "Buscar categorías",
      "inputCategoryForPartner": "Ingrese el nombre de la categoría que identifica a tu producto",
    },
    "draft": {
      "title": "Resumen",
      "titleDescription": "Vista preliminar de tu publicación en ",
      "subtitle": "Resumen de tu listing",
      "name": "Nombre",
      "brand": "Marca",
      "sku": "SKU",
      "measures": "Medidas",
      "width": "Ancho",
      "height": "Alto",
      "length": "Largo",
      "weight": "Peso",
      "images": "Imágenes",
      "preferredPrice": "Precio ideal",
      "components": "Ingredientes",
      "ready": "¡Ya está todo listo!",
      "readyDescription": "Beber con moderación prohibida su venta a menores de 18 años.",
      "preview": "Vista previa",
      "exportExcel": "Exportar Excel",
      "validateListing": "Validar listing",
      "createNewListing": "Crear listing",
      "createUpdateNewListing": "Crear / Actualizar listing",
      "createOrder": "Crear orden de venta",
      "createAnotherListing": "Crear otro listing",
      "modalTitle": "Vista Previa"
    },
    "pending": {
      "title": "Ahora es nuestro turno",
      "description": "Tu encargado LAP analizará la información y te enviará una propuesta a medida. Serás notificado vía e-mail para validar o reformular."
    },
    "proposal": {
      "title": "Tu encargado LAP armó esta propuesta a medida.",
      "accept": "Aceptar",
      "remake": "Reformular"
    },
    "acceptedProposal": {
      "title": "Aceptaste la propuesta.",
      "description": "Ya puedes continuar con las siguientes etapas",
      "nextStep": "Próximo paso",
      "remake": "Reformular"
    }
  },
  "modalAddProductVariant": {
    "alertMessage": "Variación",
    "input1": "Título",
    "input2": "Marca",
    "input3": "Bullet Point 1",
    "input4": "Bullet Point 2",
    "input5": "Bullet Point 3",
    "input6": "Bullet Point 4",
    "input7": "Bullet Point 5",
    "input8": "Descripción",
    "input9": "Ingredientes o componentes",
    "input10": "Peso",
    "input11": "Sabor",
    "input12": "Precio de referencia",
    "input13": "SKU",
    "input14": "Código EAN/UPC",
    "input15": "Pack"
  },
  "orders": {
    "section1": "Restock",
    "section2": "Restock > Nueva",
    "subtitle": "Planificación de pedidos para envíos hacia centros de distribución de las plataformas de venta.",
    "inputSearch": "Ver información de ",
    "buttonSearch": "Buscar",
    "buttonAdd": "Agregar",
    "buttonSave": "Guardar",
    "sideBarItem1": "Contenido",
    "sideBarItem2": "Cajas",
    "sideBarItem3": "Estado",
    "tab1": "Tu propuesta",
    "tab2": "Propuesta LAP",
    "alertMessage": "Tu encargado LAP armó esta propuesta a medida.",
    "alertButtonClose": "Cancelar",
    "alertButtonGoBack": "Volver",
    "alertButtonSave": "Guardar borrador",
    "modalVariantsButtonSave": "Guardar",
    "alertButtonSend": "Enviar",
    "rejectProposal": "Reformular propuesta",
    "rejectMessage": "Comentario",
    "draftSavedSuccessfully": "Borrador guardado con éxito",
    "sentSuccessfully": "Enviado correctamente",
    "confirmGoBack": "Está seguro que desea retorceder al paso anterior?",
    "confirmDelete": "Está seguro que desea eliminar la orden?",
    "documents": {
      "OTHER": {
        label: "Otro",
        comment: ""
      },
      "AMAZON_PRODUCT_LABEL": {
        label: "Etiqueta Producto Amazon",
        comment: "Instrucciones: Pegar una en cada producto, tapando/retirando los otros códigos de barra.\nIdentificación: Revisar códigos FNSKU en la orden de compra."
      },
      "AMAZON_BOX_LABEL": {
        label: "Etiqueta Caja Amazon",
        comment: "Instrucciones: Pegar una al costado de la caja.\nIdentificación: Revisar códigos SKU en la orden de compra. PARA 1 SKU POR CAJA\nIdentificación: Revisar numeración en la orden de compra. PARA +1 SKU POR CAJA"
      },
      "FEDEX_BOX_LABEL": {
        label: "Etiqueta Caja FedEX",
        comment: "Instrucciones: Pegar una al costado de la caja.\nSeleccionar una caja como principal (número 1). Solicitar una ventana adhesiva (sobre transparente) al recolector y pegarla en la caja 1 para documentos.\nCaja 1 - Pegar Etiqueta: Página 1\nCaja 1 - Ventana: Página 2, 3 y 4 + Commercial Invoice + Certificado de Origen + Prior Notice\nCaja 2 - Pegar Etiqueta: Página 5\nCaja 3 - Pegar Etiqueta: Página 6\nY así sucesivamente hasta la caja n."
      },
      "COMMERCIAL_INVOICE": {
        label: "Commercial Invoice",
        comment: ""
      },
      "ORIGIN_CERTIFICATE": {
        label: "Certificado de Origen",
        comment: ""
      },
      "PRIOR_NOTICE": {
        label: "Prior Notice",
        comment: ""
      }

    },
    "newOrder": {
      "tab1": "Orden",
      "tab2": "Cajas",
      "exportButton": "Exportar",
      "draftButton": "Borrador",
      "exportButtonBoxes": "Exportar por caja",
      "confirmButton": "Confirmar",
      "shippingButton": "Despachar",
      "switchText": "sólo órdenes",
      "changeStateModal": {
        "title": "Estado"
      },
      "table": {
        "title": "Listado de Órdenes",
        "buttonNewOrder": "Nueva orden",
        "buttonModal": "Acciones masivas",
        "buttonFilter": "Filtros",
        "search": "Buscar",
        "orderColumns": {
          "id": "LAP ID",
          "created": "CREACIÓN",
          "account": "CLIENTE",
          "cost": "COSTO",
          "amount": "UNIDADES",
          "stage": "STAGE",
          "actions": "ACTIONS",
        },
        "filterOptions": {
          "input": "Buscar ",
          "results": "resultados",
          "shipping_id": "Amazon ID",
          "order_id": "LAP ID",
          "account": "Cliente",
          "sku": "SKU",
          "asin": "ASIN",
        },
        "modal": {
          "buttonSend": "Enviar",
          "buttonCancel": "Cancelar",
          "buttonUpdate": "Actualizar",
          "title": "Acciones masivas",
          "tab1": "Doc. de transporte",
          "tab1Title": "Asignar o modificar documentos de transporte",
          "tab2": "Cambiar estado",
          "tab2Title": "Cambiar el estado de las órdenes",
          "tab3": "Notificaciones",
          "tab3Title": "Notificaciones masivas para éstas órdenes",
          "transportDocumentsForm": {
            "documentInput": "Documento de transporte",
            "documentInputInfo": "Documento asociado a reserva del envío.",
            "shippingtypeInput": "Tipo de envío",
            "shippingtypeInputInfo": "Lorep ipsum .......",
            "containerInput": "Container",
            "containerInputInfo": "Lorep ipsum .......",
          },
          "changeStateForm": {
            "stateInput": "Estado de la órden",
            "stateInputInfo": "Estado del envío de la carga.",
            "noteTextArea": "Agregar nota",
            "noteTextAreaInfo": "Registro de eventos y observaciones.",
          },
          "notificationsForm": {
            "templateInput": "Plantilla",
            "templateInputInfo": "Registro de eventos y observaciones.",
            "contentTextArea": "Contenido",
            "contentTextAreaInfo": "Registro de eventos y observaciones.",
          },


        }
      },
      "contents": {
        "title": "Composición de la orden",
        "titleDescription": "Configuración de transporte y definición de cantidades totales.",
        "subtitle1": "Transporte y costo de envío",
        "subtitleDescription1": "Configuración de transporte.",
        "subtitle2": "Listings",
        "subtitleDescription2": "Definición de cantidades por producto.",
        "subtitle3": "Documentos adjuntos",
        "subtitleDescription3": "Documentos relevantes de transporte, permisos y guías.",
        "subtitle4": "Registros / Notas",
        "subtitleDescription4": "Registro de eventos y observaciones.",
        "input1": "Cuenta",
        "input2": "Tipo de transporte",
        "input3": "Cantidad de pallets",
        "input3subinput1": "Número",
        "input3subinput2": "Link",
        "input4": "Origen",
        "input5": "Destino",
        "input6": "Costo de envío de la orden en Dólares",
        "input7": "Notas de transporte y costos de envío",
        "input8": "Notas",
        "LAPComment": "Comentario LAP",
        "LAPCommentDescription": "Comentario LAP",
        "shippingDocumentCreate": "Crear documento de transporte",
        "shippingDocumentUpdate": "Editar documento de transporte"
      },
      "boxes": {
        "title": "Configuración de cajas",
        "titleDescription": "Configuración de las cajas y distribución de las unidades.",
        "subtitle1": "Datos de cajas",
        "subtitleDescription1": "Definición de medidas y pesos por caja.",
        "subtitle2": "SKU por caja",
        "subtitleDescription2": "Distribución de unidades por producto y por caja.",
        "subtitle2text1": "UNIDADES EN LA ORDEN",
        "subtitle2text2": "UNIDADES EN CAJA",
        "subtitle3": "Documentos adjuntos",
        "subtitleDescription3": "Description",
        "subtitle4": "Registros",
        "subtitleDescription4": "Description",
        "LAPComment": "Comentario LAP",
        "LAPCommentDescription": "Comentario LAP",
        "radioGroup1": "Tipo de envío",
        "radioGroup1Description": "Definición de tipo de caja para el envío.",
        "radioGroup1Checkbox1": "1 SKU por caja",
        "radioGroup1Checkbox2": "Varios SKU por caja",
        "radioGroup2": "Unidad de medida",
        "radioGroup2Description": "Description",
        "input1": "Cantidad de cajas",
        "textAreaForNote": "Notas de Cajas",
        "textAreaForNoteDescription": "Description",
        "input8": "Notas",
        "boxDataColumns": {
          "id": "ID CAJA",
          "width": "ANCHO",
          "height": "ALTO",
          "long": "LARGO",
          "weight": "PESO",
        },
        "skuPerBoxColumns": {
          "sku": "SKU",
          "amountBoxes": "CANTIDAD DE CAJAS",
          "units": "UNIDADES DE VENTA",
          "unitsPerBox": "UNIDADES POR CAJA",
          "boxes": "CAJAS",
          "box": "CAJA",
        },
        "spanSkuPerBoxTable": "TOTAL POR CAJA",
        "medidasTooltip": "Maximo 25 in / 63.5 cm ",
        "pesoTooltip": "Maximo 50Kgs / 110Lbs",
        "unitsPerBox": "Maximo 150 unidades.",

      },
      "state": {
        "title": "Estado de la orden",
        "titleDescription": "Estado del envío de la carga.",
        "subtitle1": "Documentos adjuntos",
        "subtitleDescription1": "Description",
        "subtitle2": "Registros",
        "subtitle3": "Agregar Nota",
        "LAPComment": "Comentario LAP",
        "LAPCommentDescription": "Comentario LAP",
        "sendByFedEX": "Enviar por FedEX",
        "confirmSendByFedEX": "Está seguro que desea enviarlo mediante FedEX?",
      }
    }
  },
  "analytics": {
    "title": "Analytics",
    "subtitle": "Visualiza las ventas e inventario de cada marketplaces en un período de tiempo determinado",
    "sales": {
      "title": "Ventas",
      "subtitle": "Visualiza datos de Ventas.",
    },
    "stock": {
      "title": "Stock",
      "subtitle": "Visualiza datos de Stock.",
    },
    "marketing": {
      "title": "Marketing",
      "subtitle": "Lorem Ipsum is simply dummy text of the printing and",
    },
    "visits": {
      "title": "Visitas e Impresiones",
      "subtitle": "Lorem Ipsum is simply dummy text of the printing and",
    }
  },
  'isRequired': 'es requerido!',
  common: {
    cancel: 'Cancelar',
    reset: 'Reiniciar',
    save: 'Guardar',
    search: 'Buscar',
    filters: 'Filtros',
    remove: 'Eliminar',
    query: 'Consulta',
    apply: 'Aplicar',
    clean: 'Limpiar',
    viewAll: 'Ver Todo',
    load: 'Cargar',
  },
  dashboard: {
    required: {
      rangeOne: 'Por favor ingresar un rango de fecha.',
      rangeTwo: 'Por favor ingresar un rango de fecha.',
    },
    fields: {
      partner: 'Vendedor',
      sku: 'SKU',
      marketplace: 'Marketplace',
      category: 'Performance',
      dateRangeOne: 'Periodo',
      dateRangeTwo: 'Comparar con',
      compare: 'Comparar',
      expandable: 'Expandir'
    },
    categories: {
      stock: 'Stock',
      sales: 'Ventas'
    },
    marketplaces: {
      Amazon: 'Amazon US',
      AmazonMexico: 'Amazon Mexico',
      AmazonCanada: 'Amazon Canada',
      AmazonBrazil: 'Amazon Brazil',
      Ebay: 'Ebay US',
      EbayCanada: 'Ebay Canada',
      EbaySpain: 'Ebay España',
      EbayGermany: 'Ebay Alemania',
      Walmart: 'Walmart',
      Shopify: 'Shopify',
    },
    "saveToLocalStorageOk": "La consulta se guardo correctamente.",
    "deleteQuery": "La consulta se elimino correctamente.",
  }
}
