import * as React from "react"

function SvgTruck({ height, width, fill, ...rest }) {
    return (
        <svg
            baseProfile="basic"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 55 55"
            height={height ? height : 11}
            width={width ? width : 11}
            {...rest}
        >
            <g fill={fill ? fill : "#2F86BB"}>
                <path d="M30.82 10.99c2.39 0 4.78.07 7.17-.02 1.75-.06 2.66.68 3.21 2.31.96 2.85 2.19 5.61 3.1 8.47.63 1.99 1.67 3.14 3.83 3.31 1.3.1 2.56.55 3.85.77 1.46.24 2.15 1.02 2.14 2.51-.02 4.47.03 8.95-.02 13.42-.02 1.86-.45 2.22-2.42 2.26-2.93.05-5.86.06-8.79 0-1.38-.03-2.22.33-2.82 1.75-1.35 3.19-3.93 4.99-7.4 5.04-3.56.04-6.18-1.76-7.68-4.99-.5-1.08-1.04-1.8-2.34-1.83-1.23-.03-1.71-.88-1.76-2.02-.02-.46-.02-.93-.02-1.39 0-8.72-.01-17.43 0-26.15 0-3.12.3-3.4 3.47-3.42 2.16-.03 4.32-.02 6.48-.02zm2.19 13.41s0 .01 0 0c1.85 0 3.71.05 5.56-.01 1.62-.06 1.82-.34 1.35-1.8-.57-1.76-1.25-3.49-1.82-5.25-.33-1.01-.89-1.56-1.99-1.55-2.86.02-5.71.03-8.57-.01-1.31-.02-1.77.58-1.73 1.84.06 1.7.05 3.4 0 5.09-.03 1.17.47 1.7 1.64 1.69 1.86-.01 3.71 0 5.56 0zm4.49 18.16c.01-2.76-2.24-5.05-4.95-5.04-2.79.01-4.95 2.19-4.93 5 .01 2.79 2.22 5.07 4.91 5.08 2.66.01 4.96-2.31 4.97-5.04zM.9 6.43c.01-2.14.06-2.18 2.22-2.2 3.32-.02 6.63-.04 9.95.01 3.29.05 4.41 1.21 4.41 4.53.02 10.95.01 21.9 0 32.85 0 2.34-.04 2.38-2.29 2.4-3.93.03-7.87.02-11.8 0C.93 44.01.9 43.99.9 41.59.88 35.73.88 12.29.9 6.43z" />
            </g>
        </svg>
    )
}

export default SvgTruck