import moment from 'moment';
import i18n from 'i18next';

const filterRenders = {
  enumerator: (i18nStartPath) => (value) =>
    value ? i18n.t(`${i18nStartPath}.${value}`) : null,
  enumeratorMultiple: (i18nStartPath) => (values) =>
    values
      ? values
          .map((value) => i18n.t(`${i18nStartPath}.${value}`))
          .join(', ')
      : null,
  generic: () => (value) => value,
  stringArray: () => (value) => (value || []).join(', '),
  json: () => (value) =>
    value ? JSON.stringify(value, null, 2) : null,
  decimal: (fractionDigits) => (value) =>
    formatDecimal(value, fractionDigits),
  boolean: (trueLabel, falseLabel) => (value) =>
    value == null
      ? null
      : Boolean(value)
      ? trueLabel || i18n.t('yes')
      : falseLabel || i18n.t('no'),
  date: () => (value) => formatDate(value),
  dateRange: () => (value) => {
    if (!value || !value.length) {
      return null;
    }

    const start = value[0];
    const end = value.length === 2 && value[1];

    if (!start && !end) {
      return null;
    }

    if (start && !end) {
      return `> ${formatDate(start)}`;
    }

    if (!start && end) {
      return `< ${formatDate(end)}`;
    }

    return `${formatDate(start)} - ${formatDate(end)}`;
  },
  datetime: () => (value) => formatDatetime(value),
  datetimeRange: () => (value) => {
    if (!value || !value.length) {
      return null;
    }

    const start = value[0];
    const end = value.length === 2 && value[1];

    if (!start && !end) {
      return null;
    }

    if (start && !end) {
      return `> ${formatDatetime(start)}`;
    }

    if (!start && end) {
      return `< ${formatDatetime(end)}`;
    }

    return `${formatDatetime(start)} - ${formatDatetime(
      end,
    )}`;
  },
  decimalRange: (fractionDigits) => (value) => {
    if (!value || !value.length) {
      return null;
    }

    const start = value[0];
    const end = value.length === 2 && value[1];

    if (start == null && end == null) {
      return null;
    }

    if (start != null && end == null) {
      return `> ${formatDecimal(start, fractionDigits)}`;
    }

    if (start == null && end != null) {
      return `< ${formatDecimal(end, fractionDigits)}`;
    }

    return `${formatDecimal(
      start,
      fractionDigits,
    )} - ${formatDecimal(end, fractionDigits)}`;
  },
  range: () => (value) => {
    if (!value || !value.length) {
      return null;
    }

    const start = value[0];
    const end = value.length === 2 && value[1];

    if (
      (start == null || start === '') &&
      (end == null || end === '')
    ) {
      return null;
    }

    if (start != null && (end == null || end === '')) {
      return `> ${start}`;
    }

    if ((start == null || start === '') && end != null) {
      return `< ${end}`;
    }

    return `${start} - ${end}`;
  },
};

function formatDecimal(value, fractionDigits) {
  return value
    ? fractionDigits
      ? Number(value).toFixed(fractionDigits)
      : Number(value)
    : null;
}

function formatDate(value) {
  return value ? moment(value).format('YYYY-MM-DD') : null;
}

function formatDatetime(value) {
  return value
    ? moment(value).format('YYYY-MM-DD HH:mm')
    : null;
}

export default filterRenders;
